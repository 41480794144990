import indexCss from "bundle-text:./index.css";
import Hls from "hls.js";
import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

const baseUrl =
  process.env.BASE_URL !== "" ? process.env.BASE_URL : window.location;
const assetsUrl = `${baseUrl}/lib/assets/imgs`;
const currentScript = document.currentScript;

window.CascadePro = {
  elementId: null,
  start: function () {
    this.elementId = this.makeId(7);
    this.includeCss();
    this.injectContainer();
    this.resetContainer();
    this.inject();
    this.startSlider();
  },
  makeId: function (length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  },
  injectContainer: function () {
    const divElement = document.createElement("div");
    divElement.id = this.elementId;
    divElement.className = "cc-pro-x1";
    currentScript.insertAdjacentElement("afterend", divElement);
  },
  inject: function () {
    const divElement = document.createElement("div");

    // Sections
    const playLikePro = `
		<div>
			<img class="section2-img" src="${assetsUrl}/Play_Like_a_Pro.png">
		</div>
	`;

    const proVision = `
		<div class="section">
			<div class="section-video">
				<video id="proVision-video" autoplay="true" muted playsinline></video>
			</div>
			<div class="section-container">
				<div class="section-logo">
					<img src="${assetsUrl}/Pro_Vision_Logo.svg" alt="Pro_Vision_Logo">
				</div>
				<div>
					<h2 class="section-title">TOP-TO-BOTTOM SIGHTLINES </h2>
					<p class="section-text">
						How you see the game will never be the same.
						Experience improved focal range with extended
						ground views with the inclusion of a second,
						power-pressed Visionbar.
					</p>
				</div>
			</div>
		</div>
		`;

    const quickLip = `
		<div class="section">
			<div class="section-container">
				<div class="section-logo">
					<img src="${assetsUrl}/Quiklip_Logo.svg" alt="Quiklip_Logo">
				</div>
				<div>
					<h2 class="section-title">THE FUTURE OF FASTENERS</h2>
					<p class="section-text">
						Our self-fastening, revolutionary QuikKlip
						integration offers the speed and convenience 
						players love with a secure fit and magnetized
						lock they can trust. 
					</p>
				</div>
			</div>
			<div class="section-video">
				<video id="quickLip-video" autoplay="true" muted playsinline></video>
			</div>
		</div>
		`;

    const proFit = `
		<div class="section">
			<div class="section-video">
				<video id="proFit-video" autoplay="true" muted playsinline></video>
			</div>
			<div class="section-container">
				<div class="section-logo">
					<img src="${assetsUrl}/Pro_Fit_Logo.svg" alt="Pro_Fit_Logo">
				</div>
				<div>
					<h2 class="section-title">LESS EXPOSURE. BETTER FIT.</h2>
					<p class="section-text">
						Ergonomically-refined rear panel engineered
						for long-lasting comfort specifically around the occipital base at the back of the skull. An extended rear
						panel, revised puck system and supportive neckroll results in a natural and personalized fit for all.
					</p>
				</div>
			</div>
		</div>
		`;

    const proFit2 = `
		<div class="section">
			<div class="section-container">
				<div class="section-logo">
					<img src="${assetsUrl}/Pro_Fit_Logo.svg" alt="Pro_Fit_Logo">
				</div>
				<div>
					<h2 class="section-title">COMFORT WHEN THINGS HEAT UP</h2>
					<p class="section-text">
						We’ve dialed in our sweat management with a dry and light moisture-wicking fabric-over-foam system to keep players
						comfortable when things get heated.
					</p>
				</div>
			</div>
			<div class="section-video">
				<video id="proFit2-video" autoplay="true" muted playsinline></video>
			</div>
		</div>
		`;

    const sectionCards = `
		<div class="section-cards-container">
			<div class="section-card">
				<img class="section-card-img" src="${assetsUrl}/XRS_PRO_QUICK_CLIP_WHITE_BLACK_WHITE_ProfileLeft_1.png" alt="XRS_PRO_QUICK_CLIP_WHITE_BLACK_WHITE_ProfileLeft_1">
				<div class="section-card-container text-animation anima-1">
					<h2 class="section-title moving-text">DOUBLE-JAW DEFLECTION</h2>
					<p class="section-text moving-text">Exterior double jawbone differentiates more
					surface area to increase ball impact attenuation.</p>
				</div>
			</div>

			<div class="section-card2 section-card">
				<div class="section-card-container text-animation anima-2">
					<h2 class="section-title margin-text-2">XFLOW</h2>
					<p class="section-text">Optimal ventilation keeps your head safe while
					allowing cool air in and hot air out.</p>
				</div>
				<img class="section-card-img card-img-bottom" src="${assetsUrl}/XRS-PRO-QUICK-CLIP_WHITE_BLACK_WHITE_Back_1.png" alt="XRS-PRO-QUICK-CLIP_WHITE_BLACK_WHITE_Back_1">
			</div>

			<div class="section-card">
				<img class="section-card-img" src="${assetsUrl}/XRS-PRO-QUICK-CLIP_WHITE_BLACK_WHITE_AngleLeftRear_2.png" alt="XRS-PRO-QUICK-CLIP_WHITE_BLACK_WHITE_AngleLeftRear_2">
				<div class="section-card-container text-animation anima-3">
					<h2 class="section-title">CALCULATED BALANCE POINT</h2>
					<p class="section-text">Precise balance point ergonomically distributes mass so it feels like you’re wearing nothing.</p>
				</div>
			</div>
		</div>`;

    const customization = `
	<div class="helmets-slider">
		<div id="customization-slider" class="splide" role="group">
			<div class="splide__track">
				<ul class="splide__list">
					<li class="splide__slide">
						<img src="${assetsUrl}/Navy%20helmet%201.png" alt="Navy Helmet">
					</li>
					<li class="splide__slide">
						<img src="${assetsUrl}/Teal%20helmet%201.png" alt="Teal Helmet">
					</li>
					<li class="splide__slide">
						<img src="${assetsUrl}/Red%20helmet%201.png" alt="Red Helmet">
					</li>
					<li class="splide__slide">
						<img src="${assetsUrl}/Gold%20helmet%201.png" alt="Gold Helmet">
					</li>
					<li class="splide__slide">
						<img src="${assetsUrl}/Green%20helmet%201.png" alt="Green Helmet">
					</li>
				</ul>
			</div>
		</div>
		<div class="section-card-container section-customization-container">
			<h2 class="section-title">CUSTOMIZATION</h2>
			<p class="section-text">Unify the team with five customizable zones for over 9.6 MILLION color
				combinations.
			</p>
		</div>
	</div>
  `;

    // Injection
    divElement.innerHTML = playLikePro;
    divElement.innerHTML += proVision;
    divElement.innerHTML += quickLip;
    divElement.innerHTML += proFit;
    divElement.innerHTML += proFit2;
    divElement.innerHTML += sectionCards;
    divElement.innerHTML += customization;

    const element = document.getElementById(this.elementId);
    element.appendChild(divElement);

    // Video player Function
    window.addEventListener("load", videoScroll);
    window.addEventListener("scroll", videoScroll);

    if (Hls.isSupported()) {
      const proVision = document.getElementById("proVision-video");
      const quickLip = document.getElementById("quickLip-video");
      const proFit = document.getElementById("proFit-video");
      const proFit2 = document.getElementById("proFit2-video");

      const hlsPV = new Hls();
      hlsPV.attachMedia(proVision);
      hlsPV.on(Hls.Events.MEDIA_ATTACHED, () => {
        hlsPV.loadSource(
          "https://customer-h9bux5e1jffm81yt.cloudflarestream.com/d4fe8e4a6c9f0ddc166581a5528f0721/manifest/video.m3u8"
        );
      });

      const hlsQL = new Hls();
      hlsQL.attachMedia(quickLip);
      hlsQL.on(Hls.Events.MEDIA_ATTACHED, () => {
        hlsQL.loadSource(
          "https://customer-h9bux5e1jffm81yt.cloudflarestream.com/7dfa834ea3dcd57c244114a6089a6631/manifest/video.m3u8"
        );
      });

      const hlsPF = new Hls();
      hlsPF.attachMedia(proFit);
      hlsPF.on(Hls.Events.MEDIA_ATTACHED, () => {
        hlsPF.loadSource(
          "https://customer-h9bux5e1jffm81yt.cloudflarestream.com/fdbaeca470129b83abd734574e18c5ce/manifest/video.m3u8"
        );
      });

      const hlsPF2 = new Hls();
      hlsPF2.attachMedia(proFit2);
      hlsPF2.on(Hls.Events.MEDIA_ATTACHED, () => {
        hlsPF2.loadSource(
          "https://customer-h9bux5e1jffm81yt.cloudflarestream.com/8cf6e56ec3703db9f91023f6d2a85727/manifest/video.m3u8"
        );
      });
    }

    function videoScroll() {
      if (document.querySelectorAll("video[autoplay]").length > 0) {
        var windowHeight = window.innerHeight,
          videoEl = document.querySelectorAll("video[autoplay]");

        for (var i = 0; i < videoEl.length; i++) {
          var thisVideoEl = videoEl[i],
            videoHeight = thisVideoEl.clientHeight,
            videoClientRect = thisVideoEl.getBoundingClientRect().top;

          if (
            videoClientRect <= windowHeight - videoHeight * 0.5 &&
            videoClientRect >= 0 - videoHeight * 0.5
          ) {
            thisVideoEl.play();
            thisVideoEl.autoplay = false;
          } else {
            thisVideoEl.pause();
          }
        }
      }
    }

    // Text animation function
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const elements = document.querySelectorAll(".text-animation");
    elements.forEach((element) => observer.observe(element));
  },
  includeCss: function () {
    // inject <style> tag for css
    const style = document.createElement("style");
    style.textContent = indexCss;
    style.type = "text/css";
    var x = document.getElementsByTagName("head")[0];
    x.appendChild(style);
  },
  resetContainer: function () {
    const element = document.getElementById(this.elementId);
    element.style.all = "unset";
  },
  startSlider: function () {
    var splide = new Splide("#customization-slider", {
      arrows: false,
      pagination: false,
      gap: 60,
      type: "loop",
      drag: "free",
      focus: "center",
      height: "480px",
      perPage: 3,
      autoScroll: {
        speed: 0.5,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      cover: false,
      direction: "rtl",
    });
    splide.mount({ AutoScroll });
  },
};

(function () {
  window.CascadePro.start();
})();
